import React from "react";
import styled from "styled-components";

import { Headline } from "./index";

import * as theme from "../../theme";

const PhoneItem = props => {
  const { headline, number } = props;
  return (
    <div>
      <Headline>{headline}</Headline>
      <TelLink href={"tel:" + number}>
        <span>{number}</span>
      </TelLink>
    </div>
  );
};

export default PhoneItem;

const TelLink = styled.a`
  &:hover {
    color: ${theme.color.fern};
  }
`;
