import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import DropDown from "./DropDown"
import useMenuItems from "../../hooks/useMenuItems"
import * as theme from "../../theme"

const MainMenu = (props) => {
  const { path } = props

  const menuItems = useMenuItems("main-menu")

  return (
    <Menu {...props}>
      {menuItems &&
        menuItems.map((item, index) => {
          const { id, childItems, connectedNode, url, label } = item
          if (!childItems?.nodes?.length) {
            return (
              <Item
                key={id}
                to={url}
                className='menu-item'
                activeClassName='active-item'
              >
                {label}
              </Item>
            )
          } else {
            return (
              <DropDown
                key={id}
                index={index}
                to={url}
                className='menu-item'
                activeClassName='active-item'
                items={childItems}
                // path={url}
                parentId={id}
              >
                {label}
              </DropDown>
            )
          }
        })}
    </Menu>
  )
}

export default MainMenu

const Menu = styled.nav`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  @media screen and (min-width: 1024px) {
    justify-content: flex-start;
  }
  @media screen and (max-width: 799px) {
    display: none;
  }
  .active-item {
    color: ${theme.color.fern};
  }
  .menu-item {
    letter-spacing: 1px;
    text-decoration: none;
    @media screen and (min-width: 1024px) {
      padding: 25px 30px;
    }
    padding: 25px 10px;
    height: 100%;
    &:hover {
      color: ${theme.color.fern};
    }
  }
`

const Item = styled(Link)`
  font-family: "Fivo Sans Modern Regular", sans-serif;
  text-transform: uppercase;
  font-size: 14px;
  color: ${theme.color.grey};
`
