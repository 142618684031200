const color = {
  nero: "#202020",
  blackcurrant: "#17141B",
  fern: "#61B05D",
  accent: "#292929",
  grey: "#D8D8D8",
  dark: "#090909",
  lightgrey: "#505050"
};

export { color };
