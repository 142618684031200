import React, { useEffect, useContext } from "react";
import styled from "styled-components";
import Headroom from "react-headroom";
import HamburgerMenu from "react-hamburger-menu";
import Link from "./Link";
import MobileMenu from "./menus/MobileMenu";
import DesktopMenu from "../components/menus/DesktopMenu";
import Social from "./Social";
import HeaderLogo from "../images/logos/SKANA-est1987_pos.svg";
import { StateContext } from "../store/Context";
import * as theme from "../theme";

const Header = (props) => {
  const { path } = props;
  const { state, dispatch } = useContext(StateContext);

  useEffect(() => {
    dispatch({ type: "SET_MENU", payload: false });
    dispatch({ type: "SET_DROPDOWN", payload: null });
  }, [path]);

  return (
    <Container menuState={state.menuState}>
      <Headroom disableInlineStyles pinStart={60}>
        <FlexBox>
          <HeaderLeft className="header-left">
            <LogoContainer
              onClick={() => dispatch({ type: "SET_MENU", payload: false })}
              to={"/"}
              className="logo-container"
            >
              <HeaderLogo />
            </LogoContainer>
            <DesktopMenu className="main-menu" path={path} />
          </HeaderLeft>
          <HeaderRight>
            <Social className="menu-icons" fullHeight width="100%" />
            <QuoteButton to="/contact">
              Request <br /> a Quote
            </QuoteButton>
            <Mobile
              onClick={() =>
                dispatch({ type: "SET_MENU", payload: !state.menuState })
              }
            >
              <HamburgerMenu
                color="white"
                isOpen={state.menuState}
                width={18}
                height={15}
                strokeWidth={1}
                menuClicked={() => ""}
              />
            </Mobile>
          </HeaderRight>
        </FlexBox>
      </Headroom>
      <MobileMenu>
        <Social justify="flex-start" />
      </MobileMenu>
    </Container>
  );
};

export default Header;

const Mobile = styled.div`
  padding: 25px;
  cursor: pointer;
  @media screen and (min-width: 800px) {
    display: none;
  }
`;

const FlexBox = styled.div`
  max-height: ${theme.variables.headerHeight};
  display: flex;
  background: ${theme.color.nero};
  color: white;
  justify-content: space-between;
  align-items: center;
`;

const Container = styled.div`
  background: ${theme.color.nero};
  @media (min-width: ${theme.breakpoint.medium}) {
    margin-top: 60px;
  }
  .headroom {
    top: 0;
    z-index: 100;
    width: 100%;
    margin: 0 auto;
    max-width: 1600px;
    @media (min-width: ${theme.breakpoint.medium}) {
      width: ${theme.variables.pageWidth};
    }
  }
  .headroom--unfixed {
    position: relative;
    transform: translateY(0);
    width: 100%;
    @media screen and (max-width: 800px) {
      ${(props) =>
        props.menuState ? "position: fixed;" : "position: relative;"}
    }
    .logo-container {
      box-shadow: 0px 0px 47px 0px rgba(0, 0, 0, 0.4);
    }
  }
  .headroom--scrolled {
    transition: transform 200ms ease-in-out;
  }
  .headroom--unpinned {
    ${(props) =>
      props.menuState
        ? "transform: translateY(0%);"
        : "transform: translateY(-100%);"}
  }
  .headroom--pinned {
    transform: translateY(0%);
  }
  .headroom--pinned,
  .headroom--unpinned {
    position: fixed;
    box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.3);
    .logo-container {
      transform: translateX(0) scale(1);
      @media (min-width: 800px) {
        transform: translateX(-21%) scale(0.58);
      }
    }
  }
`;

const HeaderLeft = styled.div`
  justify-content: flex-start;
  display: flex;
  align-items: center;
  width: 70%;
  max-width: 800px;
  -webkit-transition: transform 0.2s ease-in-out; /* For Safari 3.1 to 6.0 */
  transition: transform 0.2s ease-in-out;
`;

const HeaderRight = styled.div`
  margin-left: 16px;
  display: flex;
  justify-content: flex-end;
  width: 30%;
  max-width: 300px;
  @media screen and (max-width: 799px) {
    .menu-icons {
      display: none;
    }
  }
`;

const LogoContainer = styled(Link)`
  padding: 10px;
  @media screen and (max-width: 799px) {
    width: ${theme.variables.smallLogo};
    height: ${theme.variables.smallLogo};
    transform: translateX(0);
    padding: 5px;
  }
  will-change: transform;
  height: ${theme.variables.logoWidth}px;
  width: ${theme.variables.logoWidth}px;
  svg {
    margin-top: -10px;
  }
  transform: translateX(-${theme.variables.logoWidth / 4}px);
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  color: black;
  position: relative;
  -webkit-transition: width 0.2s ease-in-out, height 0.2s ease-in-out,
    transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out; /* For Safari 3.1 to 6.0 */
  transition: width 0.2s ease-in-out, height 0.2s ease-in-out,
    transform 0.2s ease-in-out;
`;

const QuoteButton = styled(Link)`
  font-weight: 600;
  font-family: "Fivo Sans Modern Regular", sans-serif;
  transition: color 150ms ease, background 150ms ease;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 38px;
  text-transform: uppercase;
  background: #61b05d;
  text-decoration: none;
  color: #202020;
  font-size: 16px;
  min-height: ${theme.variables.headerHeight};
  &:hover {
    background: ${theme.color.dark};
    color: ${theme.color.fern};
  }
  @media screen and (max-width: 799px) {
    display: none;
  }
`;
