import React, { createContext, useReducer } from "react";

let reducer = (state, action) => {
  const { payload } = action;
  switch (action.type) {
    case "SET_MENU":
      return { ...state, menuState: payload };
    case "SET_DROPDOWN":
      return { ...state, selectedItem: payload };
    default:
      return state;
  }
};

const initialState = { menuState: false, selectedItem: null };
const StateContext = createContext(initialState);

const StateProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <StateContext.Provider value={{ state, dispatch }}>
      {props.children}
    </StateContext.Provider>
  );
};

export { StateContext, StateProvider };
