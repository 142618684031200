import React, { useContext } from "react";
import styled, { css } from "styled-components";
import { Link } from "gatsby";
import { StateContext } from "../../store/Context";
import useMenuItems from "../../hooks/useMenuItems";

import * as theme from "../../theme";

const MobileMenu = (props) => {
  const { children } = props;
  const { state, dispatch } = useContext(StateContext);

  const menuItems = useMenuItems("main-menu");

  return (
    <Menu {...props} isOpen={state.menuState}>
      {menuItems &&
        menuItems.map((item, index) => {
          const { id, url, label } = item;
          return (
            <Item
              key={id}
              to={url}
              className="menu-item"
              activeClassName="active-item"
              onClick={() => dispatch({ type: "SET_MENU", payload: false })}
            >
              {label}
            </Item>
          );
        })}

      <Icons>{children}</Icons>
      <QuoteButton to="/contact">REQUEST A QUOTE</QuoteButton>
    </Menu>
  );
};

export default MobileMenu;
const QuoteButton = styled(Link)`
  position: absolute;
  bottom: 80px;
  left: 0;
  right: 0;
  font-weight: bold;
  text-align: center;
  background: ${theme.color.fern};
  transition: color 150ms ease, background 150ms ease;
  &:hover {
    background: ${theme.color.dark};
    color: ${theme.color.fern};
  }
`;
const openStyle = css`
  transform: translateX(0);
  opacity: 1;
`;

const closedStyle = css`
  transform: translateX(120%);
  opacity: 0;
`;

const Menu = styled.div`
  position: relative;
  ${(props) => (!!props.isOpen ? openStyle : closedStyle)}
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  display: flex;
  background: ${theme.color.nero};
  position: fixed;
  z-index: 100;
  height: 100%;
  width: 100%;
  padding-top: 20px;
  flex-direction: column;
  @media screen and (min-width: 800px) {
    display: none;
  }
  .active-item {
    color: ${theme.color.fern};
  }
  .menu-item {
    color: white;
    &:hover {
      color: ${theme.color.fern};
    }
  }
  a {
    padding: 15px 20px;
    text-decoration: none;
    color: inherit;
  }
`;
const Item = styled(Link)`
  font-family: "Fivo Sans Modern Regular", sans-serif;
  text-transform: uppercase;
  font-size: 18px;
  color: ${theme.color.grey};
`;

const Icons = styled.div`
  padding: 10px;
`;
