import React from "react";
import Helmet from "react-helmet";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import "minireset.css";
import Header from "../Header";
import Footer from "../Footer/index";
import Edges from "../Edges";
import SEO from "../SEO";
import * as theme from "../../theme";
import { StateProvider } from "../../store/Context";

const GlobalStyle = createGlobalStyle`
  ${theme.typographyString}
  html {
    p, h1, h2, h3, h4, h5, ul, li {
      color: white;
    }
    ul {
      list-style: unset;
    }
    background: ${theme.color.blackcurrant};
    a {
      color: inherit;
      text-decoration: none;
    }
  }
  .gatsby-image-wrapper {
    max-height: 100%;
  }
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;
const MainLayout = (props) => {
  const { pageContext, data } = props;

  return (
    <ThemeProvider theme={theme}>
      <StateProvider>
        <Helmet>
          <meta name="description" />
        </Helmet>

        <SEO {...pageContext} />

        <GlobalStyle />

        <Edges>
          {!data?.post && <Header path={props.location.pathname} />}
          {props.children}
          {!data?.post && <Footer />}
        </Edges>
      </StateProvider>
    </ThemeProvider>
  );
};
export default MainLayout;
