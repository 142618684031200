import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";

import FooterMenu from "../../components/menus/FooterMenu";
import PhoneItem from "./PhoneItem";
import Social from "../Social";
import QuoteButton from ".././styles/QuoteButton";
import FooterLogoSvg from "../../images/logos/SKANA-est1987_neg.svg";
import * as theme from "../../theme";

const Footer = () => {
  const footerData = useFooterQuery();

  if (!footerData) return null;
  const { phone, tollFree, fax, address, businessName } = footerData;

  return (
    <div>
      <Container>
        <MobileFooterLogo>
          <FooterLogoSvg />
          <FooterMenu />
        </MobileFooterLogo>
        <DesktopFooterLogo>
          <FooterLogoSvg />
        </DesktopFooterLogo>
        <DesktopFooterMenu>
          <FooterMenu />
        </DesktopFooterMenu>
        <Phone>
          {!!phone && <PhoneItem headline="Phone" number={phone} />}
          {!!tollFree && <PhoneItem headline="Toll-Free" number={tollFree} />}
          {!!fax && <PhoneItem headline="Fax" number={fax} />}
        </Phone>
        <Address>
          {!!businessName && <Headline>{businessName}</Headline>}
          {!!address && (
            <>
              {!!address.addressLine1 && <span>{address.addressLine1}</span>}
              {!!address.addressLine2 && <span>{address.addressLine2}</span>}
            </>
          )}
          <QuoteButton to="/contact">REQUEST A QUOTE</QuoteButton>
        </Address>
        <Social marginRight="25px" justify="flex-start" />
      </Container>
      <Copyright>
        <div>&copy; {new Date().getFullYear()} Skana Forest Products Ltd.</div>
        <div>
          <a href="https://jambaree.com/" target="_blank">
            Gatsby site development by Jambaree
          </a>
        </div>
      </Copyright>
    </div>
  );
};

export default Footer;

const useFooterQuery = () => {
  const {
    wp: {
      siteOptions: { siteOptions },
    },
  } = useStaticQuery(
    graphql`
      {
        wp {
          siteOptions {
            siteOptions {
              phone
              tollFree
              fax
              address {
                addressLine1
                addressLine2
              }
              businessName
              fax
            }
          }
        }
      }
    `
  );
  return siteOptions;
};

const Container = styled.div`
  margin-bottom: 20px;
  padding: 50px 5%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  justify-content: space-between;
  width: 100%;
  background: ${theme.color.nero};
  color: white;
  @media screen and (max-width: 449px) {
    justify-content: flex-start;
    flex-direction: column;
  }
`;

export const Headline = styled.div`
  text-transform: uppercase;
  color: ${theme.color.fern};
  font-size: 12px;
  font-weight: 600;
`;

const Phone = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 8px 30px;
`;

const Address = styled.div`
  padding: 0 8px 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const MobileFooterLogo = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-right: 5%;
  svg {
    width: 150px;
    padding-bottom: 30px;
  }
  @media screen and (min-width: 450px) {
    display: none;
  }
`;

const DesktopFooterLogo = styled.div`
  height: 100%;
  max-width: 200px;
  padding-bottom: 30px;

  svg {
    width: 200px;
  }
  @media screen and (max-width: 449px) {
    display: none;
  }
`;

const DesktopFooterMenu = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
  @media screen and (max-width: 449px) {
    display: none !important;
  }
`;

const Copyright = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
  color: #fff;
  a {
    &:hover {
      text-decoration: underline;
    }
  }
`;
