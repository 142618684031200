import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
// import styled from "styled-components";
import AnchorLink from 'react-anchor-link-smooth-scroll';

const Link = (props) => {
	const { to } = props;

	return (
		<>
			{to !== '' && !to.includes('#') ? (
				<GatsbyLink to={to} {...props} />
			) : (
				<AnchorLink href={to} {...props} />
			)}
		</>
	);
};

export default Link;
