import React from "react";
import styled from "styled-components";
import * as theme from "../theme";

export default function Edges(props) {
  return <Section {...props}>{props.children}</Section>;
}

const Section = styled.section`
  width: ${props => (props.small ? "600px" : "1600px")};
  max-width: 100%;
  margin: 0 auto;

  @media screen and (min-width: ${theme.breakpoint.medium}) {
    max-width: ${theme.variables.pageWidth};
  }

  /* @media screen and (min-width: ${theme.breakpoint.large}) {
    max-width: 90%;
  } */
`;
