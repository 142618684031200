import styled from "styled-components";
import { Link } from "gatsby";

import * as theme from "../../theme";

const QuoteButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.color.fern};
  color: ${theme.color.dark};
  padding: 15px 25px;
  margin-top: 20px;
  font-weight: 600;
  transition: color 150ms ease, background 150ms ease;
  &:hover {
    background: ${theme.color.dark};
    color: ${theme.color.fern};
  }
`;
export default QuoteButton;
