import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import * as theme from "../../theme"
import useMenuItems from "../../hooks/useMenuItems"

const FooterMenu = () => {
  const menuItems = useMenuItems("footer-menu")

  return (
    <Menu>
      {menuItems &&
        menuItems.map(({ url, label }, index) => (
          <Item key={index} to={url}>
            {label}
          </Item>
        ))}
    </Menu>
  )
}

export default FooterMenu

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 8px 16px;
  a {
    text-decoration: none;
    color: inherit;
    &:hover {
      color: ${theme.color.fern};
    }
  }
`
const Item = styled(Link)`
  font-family: "Fivo Sans Modern Regular", sans-serif;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 1px;
  color: ${theme.color.grey};
`
