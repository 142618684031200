import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";

import FacebookIcon from "../images/social/facebook.svg";
import InstagramIcon from "../images/social/instagram.svg";
import TwitterIcon from "../images/social/twitter.svg";

import * as theme from "../theme";

const Social = (props) => {
  const { facebook, instagram, twitter } = useSocialQuery();

  return (
    <Container {...props}>
      {!!facebook && (
        <a href={facebook} target="_blank">
          <FacebookIcon />
        </a>
      )}
      {!!instagram && (
        <a href={instagram} target="_blank">
          <InstagramIcon />
        </a>
      )}
      {!!twitter && (
        <a href={twitter} target="_blank">
          <TwitterIcon />
        </a>
      )}
    </Container>
  );
};

export default Social;

const useSocialQuery = () => {
  const {
    wp: {
      siteOptions: { siteOptions },
    },
  } = useStaticQuery(
    graphql`
      {
        wp {
          siteOptions {
            siteOptions {
              facebook
              instagram
              twitter
            }
          }
        }
      }
    `
  );

  return siteOptions;
};

const Container = styled.div`
  width: ${(props) => (!!props.width ? props.width : "auto")};
  display: flex;
  justify-content: ${(props) =>
    !!props.justify ? props.justify : "space-evenly"};
  align-items: flex-start;
  ${(props) => props.offsetRight && "margin-right: " + props.offsetRight + ";"}
  a {
    ${(props) => !!props.fullHeight && "height: 100%;"}
    ${(props) =>
      !!props.marginRight &&
      "margin-right:" + props.marginRight + ";"}
      
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    &:hover {
      svg {
        fill: ${theme.color.fern};
      }
    }
  }
  svg {
    fill: white;
  }
`;
