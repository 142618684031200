import React, { useRef, useEffect, useContext } from "react";
import styled, { css } from "styled-components";
import Link from "../Link";
import Parser from "html-react-parser";

import { StateContext } from "../../store/Context";

import * as theme from "../../theme";

const Dropdown = (props) => {
  const { state, dispatch } = useContext(StateContext);
  const { children, items, offset, index, to, parentId, path } = props;

  const listRef = useRef(null);
  let listWidth;
  let trimmedPath = to.substring(0, to.length - 3);

  useEffect(() => {
    if (!!listRef.current) {
      // Get width of list div
      listWidth = listRef.current.clientWidth;
      // Apply negative margin-left to center the list under the nav item
      listRef.current.style.marginLeft = Math.floor(-(listWidth / 2)) + "px";
    }
  });

  const closeMenu = () => {
    dispatch({ type: "SET_DROPDOWN", payload: null });
  };
  const openMenu = () => {
    dispatch({ type: "SET_DROPDOWN", payload: index });
  };

  return (
    <Container>
      {state.selectedItem === index && (
        <MouseAwayListener onMouseEnter={() => closeMenu()} />
      )}
      <NavItem
        to={to}
        onMouseEnter={() => openMenu()}
        onFocus={() => openMenu()}
        className="menu-item"
        activeClassName="active-item"
      >
        {children}
      </NavItem>
      <List
        ref={listRef}
        opened={state.selectedItem === index ? true : undefined}
        listWidth={listWidth}
        offset={offset}
        onMouseLeave={() => closeMenu()}
      >
        {!!items?.nodes &&
          items.nodes.map((item) => {
            const { id, url, label } = item;
            return (
              <ListItem
                key={id}
                to={url}
                onClick={() => closeMenu()}
                activeClassName="active-item"
              >
                {!!label && Parser(label)}
              </ListItem>
            );
          })}
      </List>
    </Container>
  );
};

export default Dropdown;

Dropdown.defaultProps = { offset: 60 };

const Container = styled.div`
  position: relative;
  .active-item {
    color: ${theme.color.fern};
  }
`;

const MouseAwayListener = styled.div`
  position: absolute;
  top: -10vh;
  left: -20vw;
  width: 50vw;
  height: 50vw;
  z-index: 11;
`;

const NavItem = styled(Link)`
  font-family: "Fivo Sans Modern Regular", sans-serif;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 2px;
  color: ${theme.color.grey};
  position: relative;
  z-index: 12;
  display: flex;
`;
const openAnim = css`
  opacity: 1;
  transform: scale(1);
`;
const closeAnim = css`
  opacity: 0;
  pointer-events: none;
  transform: scale(0.97);
`;
const List = styled.div`
  padding: 10px 0;
  ${(props) => (props.opened ? openAnim : closeAnim)}
  position: absolute;
  top: ${(props) => props.offset + "px"};
  left: 50%;
  z-index: 999;
  display: flex;
  flex-direction: column;
  transition: opacity 200ms ease-in-out, transform 200ms ease-in-out;
  transform-origin: 50% 0%;
  will-change: transform, opacity;
  background: ${theme.color.nero};
  box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.15);
  .active-item {
    color: ${theme.color.fern};
  }
  svg {
    position: absolute;
    top: -10px;
    left: calc(50% - 5px);
    polygon {
      fill: ${theme.color.nero};
    }
  }
`;
const ListItem = styled(Link)`
  font-family: "Fivo Sans Modern Regular", sans-serif;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 1px;
  padding: 15px 30px;
  font-weight: 500;
  position: relative;
  width: 100%;
  color: ${theme.color.grey};
  white-space: nowrap;
  text-decoration: none;
  &:hover {
    color: ${theme.color.fern};
    background: #2b2b2b;
  }
`;
